import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function PlanReadyPage() {
  return (
    <>
      <header className="mainHeader">
        <Navbar bg="light" fixed="top">
          <Container className="justify-content-center">
            <Navbar.Brand href="#home">
              <img src="assets/images/keto-logo.png" alt="logo" />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>
      <section className="planPage">
        <Container>
          <Row>
            <Col className="planPage_cnt mx-auto">
              <div className="commonHead text-center">
                <h1 className="commonHead_title">
                  Your 4-week Keto Diet
                  <br className="d-none d-md-block" />
                  plan is ready!
                </h1>
                <p className="commonHead_subtitle">
                  We'll work on your goal together
                </p>
              </div>
              <div className="planPage_imgBox">
                <span className="badge badge-danger">Your weight</span>
                <span className="badge badge-success">After 4 weeks</span>
                <img
                  className="img-fluid"
                  src="assets/images/graph.gif"
                  alt="bg-img"
                />
                <p className="infoText">
                  This is a tentative timeline based on your answers.
                </p>
                <Link className="btn btn-primary" to="/final">
                  Continue
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default PlanReadyPage;
