import { Suspense, useEffect } from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/scss/common.scss";

function RouteLayout({ path }) {
  const element = useRoutes(path);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [element]);

  return element;
}

function App() {
  return (
    <div className="App">
      <Suspense>
        <RouteLayout path={routes()} />
      </Suspense>
    </div>
  );
}

export default App;
