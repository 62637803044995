import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function FinalPage() {
  const [radioActive, setRadioActive] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <header className="mainHeader">
        <Navbar bg="light" fixed="top">
          <Container>
            <Navbar.Brand href="#home">
              <img src="assets/images/keto-logo.png" alt="logo" />
            </Navbar.Brand>
            <Button href="#getPlan" className="navbar-btn" variant="primary">
              Get My Plan
            </Button>
          </Container>
        </Navbar>
      </header>
      <section className="fitnessLevel pt-70">
        <Container>
          <div className="fitnessBox">
            <div className="fitnessBox_head d-flex  text-center">
              <p className="flex-1 font-sb">Your weight</p>
              <p className="flex-1 font-sb">Goal</p>
            </div>
            <div className="fitnessBox_img d-flex text-center">
              <div className="flex-1">
                <img src="assets/images/img-left.svg" alt="your-weight" />
              </div>
              <div className="flex-1">
                <img src="assets/images/img-right.svg" alt="goal" />
              </div>
            </div>
            <div className="fitnessBox_body d-flex">
              <div className="fitnessBox_body_left flex-1">
                <div className="item">
                  <h4 className="font-sb">Body fat</h4>
                  <p>21-24%</p>
                </div>
                <div className="item">
                  <h4 className="font-sb">Fat-Burning level</h4>
                  <ul className="list-unstyled d-flex">
                    <li className="active"></li>
                    <li className="active"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div className="fitnessBox_body_right flex-1">
                <div className="item">
                  <h4 className="font-sb">Body fat</h4>
                  <p>14-20%</p>
                </div>
                <div className="item">
                  <h4 className="font-sb">Fat-Burning level</h4>
                  <ul className="list-unstyled d-flex">
                    <li className="active"></li>
                    <li className="active"></li>
                    <li className="active"></li>
                    <li className="active"></li>
                    <li className="active"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="fitnessLevel_middle" id="getPlan">
            <div className="commonHead text-center">
              <h2 className="commonHead_title">
                Your personal
                <br />
                Keto Diet
                <br />
                plan is ready!
              </h2>
            </div>
            <div className="goalBox d-flex text-center">
              <div className="flex-1">
                <p>Goal</p>
                <h3 className="font-sb m-0 d-flex align-items-center justify-content-center">
                  <img
                    className="me-2"
                    src="assets/images/goal.svg"
                    alt="goal"
                  />
                  Fat burning furnace
                </h3>
              </div>
              <div className="flex-1">
                <p>Target weight</p>
                <h3 className="font-sb m-0 d-flex align-items-center justify-content-center">
                  <img
                    className="me-2"
                    src="assets/images/weight.svg"
                    alt="goal"
                  />
                  111 lb
                </h3>
              </div>
            </div>
            <div className="planBox pb-70">
              <form>
                <div className="planBox_list">
                  <label
                    className={`planBox_item d-flex justify-content-between ${
                      radioActive === 1 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(1)}
                  >
                    <div className="d-flex align-self-center align-items-center">
                      <Form.Check
                        className="d-flex"
                        name="planBox"
                        type="radio"
                        aria-label="radio 1"
                      />
                      <h3 className="font-bd">1-WEEK PLAN</h3>
                    </div>
                    <div>
                      <h4 className="font-bd">
                        1.43<sup>USD</sup>
                      </h4>
                      <p>per day</p>
                    </div>
                  </label>
                  <label
                    className={`planBox_item d-flex justify-content-between ${
                      radioActive === 2 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(2)}
                  >
                    <div className="d-flex align-self-center align-items-center">
                      <Form.Check
                        className="d-flex"
                        name="planBox"
                        type="radio"
                        aria-label="radio 1"
                      />
                      <h3 className="font-bd">1-Month PLAN</h3>
                    </div>
                    <div className="align-self-end">
                      <p>
                        <del>1</del>
                      </p>
                    </div>
                    <div>
                      <h4 className="font-bd">
                        0.67<sup>USD</sup>
                      </h4>
                      <p>per day</p>
                    </div>
                  </label>
                  <label
                    className={`mostPopular planBox_item d-flex justify-content-between ${
                      radioActive === 3 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(3)}
                  >
                    <div class="mostPopular_label position-absolute text-center">
                      Most Popular
                    </div>
                    <div className="d-flex align-self-center align-items-center">
                      <Form.Check
                        className="d-flex"
                        name="planBox"
                        type="radio"
                        aria-label="radio 1"
                      />
                      <h3 className="font-bd">3-MONTH PLAN</h3>
                    </div>
                    <div className="align-self-end">
                      <p>
                        <del>0.54</del>
                      </p>
                    </div>
                    <div>
                      <h4 className="font-bd">
                        0.43<sup>USD</sup>
                      </h4>
                      <p>per day</p>
                    </div>
                  </label>
                </div>
                <Button
                  onClick={handleShow}
                  variant="primary"
                  className="w-100"
                >
                  Get my plan
                </Button>
                <p className="metaText mb-0 text-center">
                  Extended monthly after 1 month intro offer <br /> at full
                  price of 29.99 USD
                </p>
              </form>
            </div>
            <div className="features pb-70">
              <div className="commonHead text-center">
                <h2 className="commonHead_title">What you get</h2>
              </div>
              <ul className="m-0 p-0 list-unstyled">
                <li>Shred pounds fast</li>
                <li>Forget about yo-yo dieting</li>
                <li>Learn new eating patterns</li>
                <li>Get toned and lean at home (no gym needed)</li>
                <li>Aquire new habits like mindful eating</li>
                <li>Deal with food cravings</li>
                <li>Enjoy improved health, wellbeing and quality of life.</li>
              </ul>
            </div>
            <div className="imageCompare pb-70">
              <div className="commonHead text-center">
                <h2 className="commonHead_title commonHead_title-sm">
                  People just like you achieved great results using our Weight
                  Loss Plans
                </h2>
              </div>
              <img
                className="img-fluid"
                src="assets/images/brie.jpg"
                alt="weight-loss-plans"
              />
            </div>
          </div>
          <div className="faqs pb-70">
            <div className="commonHead text-center">
              <h2 className="commonHead_title">People often ask</h2>
            </div>
            <div className="faqsList">
              <div className="faqsList_item">
                <h3 className="font-bd">What if I feel hungry?</h3>
                <p>
                  The menu we offer is balanced and filling, and you shouldn't
                  feel hungry. Our meal plans are developed by certified
                  nutritionists so that you could reduce cravings and stay full
                  while having a balanced and healthy diet for efficient weight
                  loss.
                </p>
              </div>
              <div className="faqsList_item">
                <h3 className="font-bd">
                  I'm worried that I'll quit fasting halfway.
                </h3>
                <p>
                  We totally get it, and that's why we made sure that our Keto
                  Plans are beginner-friendly. We help you along the way with
                  useful tips, exact timing, and complete guidance so you can
                  reap the most benefits with least effort.
                </p>
              </div>
              <div className="faqsList_item">
                <h3 className="font-bd">
                  Can I lose weight without working out?
                </h3>
                <p>
                  Sure thing! Nutrition is the most important element in weight
                  loss. However if you incorporate workouts, your body will get
                  more toned and defined. We know it's a lot to take on, and the
                  workouts are extremely easy, suitable for first-timers, and
                  will take up barely any time.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="testimonial">
        <Container>
          <div className="commonHead text-center">
            <h2 className="commonHead_title">User love our plans</h2>
          </div>
          <div className="testimonial_inner">
            <Row className="g-3">
              <Col md="4">
                <div className="tesimonialBox">
                  <div className="tesimonialBox_head">
                    <div className="user d-flex align-items-center">
                      <img
                        className="user_img"
                        src="assets/images/author1.png"
                        alt="Katie McPherson"
                      />
                      <div className="user_right d-lg-flex justify-content-between align-items-center">
                        <h4>Katie McPherson</h4>
                        <div className="rating">
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    The meals are SO easy and so delicious… very similar to
                    items I normally buy but knowing how to combine the right
                    ingredients in the right portions to guarantee weight loss
                    day by day is so convenient. I honestly don’t even feel like
                    I’m trying to lose weight but the scale reminds me that I am
                    every day! I’ve lost 15 pounds in the first month alone and
                    have more energy than I did when I thought I was “eating
                    healthy”. Thank you for putting this genius program together
                    :)
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="tesimonialBox">
                  <div className="tesimonialBox_head">
                    <div className="user d-flex align-items-center">
                      <img
                        className="user_img"
                        src="assets/images/author3.png"
                        alt="Keith Elliot"
                      />
                      <div className="user_right d-lg-flex justify-content-between align-items-center">
                        <h4>Keith Elliot</h4>
                        <div className="rating">
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    I’ve lost a whopping 42 lbs since staring on the keto diet.
                    I don’t think I would have stuck with it for as long as I
                    have without the sheer convenience of this program. 1) Print
                    weekly shopping list 2) get the ingredients 3) make the
                    meals 4) eat and lose weight. It’s so simple but the most
                    powerful program I’ve tried by a long shot. I’m telling
                    everyone - you’ve finally cracked the code!
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="tesimonialBox">
                  <div className="tesimonialBox_head">
                    <div className="user d-flex align-items-center">
                      <img
                        className="user_img"
                        src="assets/images/author2.png"
                        alt="NoSugarMama"
                      />
                      <div className="user_right d-lg-flex justify-content-between align-items-center">
                        <h4>NoSugarMama</h4>
                        <div className="rating">
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                          <img src="assets/images/star-active.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    I’m a beginner when it comes to keto, which is why I put off
                    starting for so long… it was just overwhelming. KetoSlim was
                    the opposite - you guys made the transition to keto super
                    easy and enjoyable. I’m saving soooo much money by only
                    buying ingredients I want to eat and everything gets used up
                    in the recipes each week! No more Uber Eats for this gal!!
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="fitnessLevel pt-70">
        <Container>
          <div className="fitnessLevel_middle mt-0">
            <div className="commonHead text-center">
              <h2 className="commonHead_title">
                Get visible result in 4 weeks
              </h2>
            </div>
            <div className="goalBox d-flex text-center">
              <div className="flex-1">
                <p>Goal</p>
                <h3 className="font-sb m-0 d-flex align-items-center justify-content-center">
                  <img
                    className="me-2"
                    src="assets/images/goal.svg"
                    alt="goal"
                  />
                  Fat burning furnace
                </h3>
              </div>
              <div className="flex-1">
                <p>Target weight</p>
                <h3 className="font-sb m-0 d-flex align-items-center justify-content-center">
                  <img
                    className="me-2"
                    src="assets/images/weight.svg"
                    alt="goal"
                  />
                  111 lb
                </h3>
              </div>
            </div>
            <div className="planBox pb-70">
              <form>
                <div className="planBox_list">
                  <label
                    className={`planBox_item d-flex justify-content-between ${
                      radioActive === 1 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(1)}
                  >
                    <div className="d-flex align-self-center align-items-center">
                      <Form.Check
                        className="d-flex"
                        name="planBox"
                        type="radio"
                        aria-label="radio 1"
                      />
                      <h3 className="font-bd">1-WEEK PLAN</h3>
                    </div>
                    <div>
                      <h4 className="font-bd">
                        1.43<sup>USD</sup>
                      </h4>
                      <p>per day</p>
                    </div>
                  </label>
                  <label
                    className={`planBox_item d-flex justify-content-between ${
                      radioActive === 2 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(2)}
                  >
                    <div className="d-flex align-self-center align-items-center">
                      <Form.Check
                        className="d-flex"
                        name="planBox"
                        type="radio"
                        aria-label="radio 1"
                      />
                      <h3 className="font-bd">1-Month PLAN</h3>
                    </div>
                    <div className="align-self-end">
                      <p>
                        <del>1</del>
                      </p>
                    </div>
                    <div>
                      <h4 className="font-bd">
                        0.67<sup>USD</sup>
                      </h4>
                      <p>per day</p>
                    </div>
                  </label>
                  <label
                    className={`mostPopular planBox_item d-flex justify-content-between ${
                      radioActive === 3 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(3)}
                  >
                    <div class="mostPopular_label position-absolute text-center">
                      Most Popular
                    </div>
                    <div className="d-flex align-self-center align-items-center">
                      <Form.Check
                        className="d-flex"
                        name="planBox"
                        type="radio"
                        aria-label="radio 1"
                      />
                      <h3 className="font-bd">3-MONTH PLAN</h3>
                    </div>
                    <div className="align-self-end">
                      <p>
                        <del>0.54</del>
                      </p>
                    </div>
                    <div>
                      <h4 className="font-bd">
                        0.43<sup>USD</sup>
                      </h4>
                      <p>per day</p>
                    </div>
                  </label>
                </div>
                <Button
                  onClick={handleShow}
                  variant="primary"
                  className="w-100"
                >
                  Get my plan
                </Button>
                <p className="metaText mb-0 text-center">
                  Extended monthly after 1 month intro offer <br /> at full
                  price of 29.99 USD
                </p>
              </form>
            </div>
          </div>
        </Container>
      </section>
      <footer className="footer text-center">
        <Container>
          <div className="footer_top">
            <img src="assets/images/award.svg" alt="" />
            <h2>Money-Back Guarantee</h2>
            <p>
              In case you don’t get visible results, you can get a full refund
              within 60 days after purchase.
            </p>
            <Link>Learn More</Link>
          </div>
          <div className="footer_bottom">
            <p>
              Copyright ® 2022 KetoSlim Diet
              <br /> All Rights Reserved
            </p>
          </div>
        </Container>
      </footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select payment method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalImg">
            <img src="assets/images/img-right.svg" alt="" />
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="tabs" className="justify-content-center">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  <img alt="" src="assets/images/upi.svg" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  <img alt="" src="assets/images/card.svg" />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="planInfo text-center">
              <p>1-month plan</p>
              <p className="mb-0">29.99 USD</p>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="upiMode text-center">
                  <Button className="no-animate w-100" variant="warning">
                    <img alt="" src="assets/images/paypal.svg" />
                    &nbsp;&nbsp;Buy Now
                  </Button>
                  <p className="mt-1">THE SAFER, EASIER WAY TO PAY</p>
                </div>
                <div className="or">or</div>
                <Button className="no-animate w-100" variant="dark">
                  Buy With &nbsp;&nbsp;
                  <img alt="g-pay" src="assets/images/g-pay.svg" />
                </Button>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="cardMode">
                  <form>
                    <Row className="gy-0">
                      <Col sm="12">
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Card number</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="XXXX XXXX XXXX XXXX"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Expiry Date</Form.Label>
                          <Form.Control type="email" placeholder="mm/yy" />
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>CVV/CVC</Form.Label>
                          <Form.Control type="email" placeholder="123" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button className="no-animate w-100" variant="primary">
                      PAY
                    </Button>
                  </form>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FinalPage;
