import FinalPage from "../components/FinalPage";
import PlanReadyPage from "../components/PlanReadyPage";

export const routes = () => {
  return [
    {
      path: "/",
      key: "/",
      element: <PlanReadyPage />,
    },
    {
      path: "/final",
      key: "/final",
      element: <FinalPage />,
    },
  ];
};
